<template>
  <article>
    <hgroup><h1>음성</h1></hgroup>
    이 샘플 예제를 수행하기 위해서는 <a href="https://dialogflow.cloud.google.com/" target="_blank">dialogflow</a>에 대해 별도의 실습이 필요합니다.<br>
    Dialogflow Quick Start: <a href="https://cloud.google.com/dialogflow/es/docs/quick" target="_blank">https://cloud.google.com/dialogflow/es/docs/quick</a>
    <h2>Prerequisites</h2>
    <ul class="checklist">
      <li>Python 3.7</li>
      <li>Flask</li>
    </ul>
    <h2>Dependency</h2>
    아래 public component를 사용하는 컴포넌트 입니다.
    <ul class="checklist">
      <li><a href="/docs/voice/reference" target="_blank">음성 + NLU (Beta)</a></li>
    </ul>
    <h2>예제</h2>
    이 샘플 예제는 레시피를 물을 수 있는 음성 예제입니다. 마이크 버튼을 누르고 발화를 하면 발화가 순차적으로 화면에 보여지며 발화가 완료되면 응답 메시지와 함께 레시피 링크가 주어집니다. <br>
    이 예제는 아래 웹소켓을 사용하여 구현되었습니다.
    <ol>
      <ul style="line-height:34px">
        <li>
          <a href="/docs/voice/reference#text3" target="_blank">ws://localhost:8009/nlu/dialogflow/stream/text</a>
        </li>
      </ul>
      <img
          src="/assets/img/getstarted17.png"
          alt=""
          style="margin-bottom: 10px; margin-top: 10px;"
      />
      <li>구글 계정을 생성 후 <a href="https://cloud.google.com/dialogflow/es/docs/quick/setup#auth" target="_blank">여기</a>를 참고하여 credential 키를 발급해주세요. JSON 파일을 잘 저장해 주세요.</li>
      <li><a href="https://dialogflow.cloud.google.com/" target="_blank">Dialogflow 콘솔</a>로 로그인하여 레시피 인텐트를 만들어 줍니다.</li>
      <li>Entities  > CREATE ENTITY 에서 다음과 같이 설정하고 SAVE 버튼을 눌러 줍니다.</li>
      <img
          src="/assets/img/getstarted18-0.png"
          alt=""
          style="margin-bottom: 60px; margin-top: 10px;"
      />
      <li>Intents > CREATE INTENT 에서 다음과 같이 설정하고 SAVE 버튼을 눌러 줍니다.</li>
      <img
          src="/assets/img/getstarted18.png"
          alt=""
          style="margin-bottom: 60px; margin-top: 10px;"
      />
      <img
          src="/assets/img/getstarted19.png"
          alt=""
          style="margin-bottom: 60px; margin-top: 10px;"
      />
      <li>
        톱니바퀴 버튼을 클릭 후 Project ID를 복사해둡니다.
      </li>
      <img
          src="/assets/img/getstarted20.png"
          alt=""
          style="margin-bottom: 60px; margin-top: 10px;"
      />
      <li>
        <a href="/assets/img/voice-sample.zip" target="_blank">소스코드</a>를 다운로드 받습니다.
      </li>
      <li>
        static/js/index.js #5-12에서 project_id, credential, rate을 적절하게 수정합니다.
      </li>
      <ul style="line-height:34px">
        <li>project_id:  4번에서 복사한 것을 붙여 넣으면 됩니다.</li>
        <li>credential: 9번의 name과 동일하게 적어주면 됩니다. 이 예제에서는 "sample"로 합니다. </li>
      </ul>
      <li>
        코드 수정 후 다른 예제와 동일한 방식으로 컴포넌트를 생성 후 배포를 합니다.
      </li>
      <div>
        <strong>배포스크립트 예시</strong>
        <pre><code class='hljs'>pip3 install -r {root}/voice_sample/requirements.txt && python3 {root}/voice_sample/app.py</code></pre>
      </div>
      <li>
        라즈베리파이 터미널에서 아래를 실행해 줍니다. 이때 1번에서 저장한 JSON 파일을 열어 내용을 복사한 후 아래 credential에 넣어 줍니다.
      </li>
      <div>
        <pre><code class='hljs'>curl --location --request POST '127.0.0.1:8009/credential' \
--header 'Content-Type: application/json' \
--data-raw '{
    "name": "sample",
    "credential": &lt;CREDENTIAL&gt;
}'</code></pre>
      </div>
      <li>
        <a href="http://localhost:8881 " target="_blank">"http://127.0.0.1:8881/"</a>에서 결과를 확인할 수 있습니다. (음성은 아직 베타로 오픈하여 불안정한 부분이 있습니다. 마이크 버튼을 누르고 음성을 말하고 5초 이상 작동을 안하면 라즈베리파이 재부팅을 하면 됩니다.)
      </li>
    </ol>
  </article>
</template>
<script>
import getStartedPage2Py from "raw-loader!@/assets/code/get-started-page2.py";
import getStartedPage2Sh from "raw-loader!@/assets/code/get-started-page2.sh";
import CodeViewer from "@/components/CodeViewer.vue";
export default {
  data() {
    return {
      getStartedPage2Py,
      getStartedPage2Sh,
    };
  },
  components: {
    CodeViewer,
  },
};
</script>
